import React from "react"

import Layout from "../components/layout"
import Page from "../components/Page/page"

class Terms extends React.Component {
  render() {
    const { location } = this.props
    return (
      <Layout location={location}>
        <Page id="6" />
      </Layout>
    )
  }
}

export default Terms
